<template>
  <Modal @closeModal="closeModal">
    <Tile class="overflow-y-auto p-4 md:p-8" style="max-height: 60vh">
      <Title :title="campaign.title" size="medium" class="mb-4 text-center" />

      <div class="flex flex-col gap-4">
        <div>
          <Title size="small" title="Description" />

          <p>{{ campaign && campaign.info && campaign.info.description ? campaign.info.description : "" }}</p>
        </div>

        <div v-if="campaignLanguages">
          <Title size="small" title="Broadcasting languages" />

          <p class="capitalize value">{{ campaignLanguages }}</p>
        </div>

        <div v-if="campaign.targets && campaign.targets.length">
          <Title size="small" title="Campaign targets" />

          <p v-for="(target, targetIndex) of campaign.targets" :key="targetIndex">
            {{ target.value }} {{ target.unit.label }}
          </p>
        </div>

        <div v-if="campaign.info && campaign.info.priceRate">
          <Title size="small" title="Rate for 1000 views" />

          <p>{{ campaign.info.priceRate }}€</p>
        </div>

        <div v-if="campaign.info && campaign.info.payableDate">
          <Title size="small" title="Payable date" />

          <p>
            {{
              new Date(campaign.info.payableDate).toLocaleDateString(undefined, {
                weekday: "short",
                year: "numeric",
                month: "short",
                day: "numeric",
              })
            }}
          </p>
        </div>

        <div>
          <Title size="small" title="Display frequency" />

          <p>Every {{ campaign.displayFreq }} minutes</p>
        </div>

        <div v-if="campaign.tools && campaign.tools.chatbot && campaign.tools.chatbot.text">
          <Title size="small" title="Chat message" />

          <p>{{ campaign.tools.chatbot.text }}</p>
        </div>

        <div v-if="campaign.tools && campaign.tools.chatbot && campaign.tools.chatbot.link">
          <Title size="small" title="Chat link" />

          <a style="line-break: anywhere" target="_blank" :href="campaign.tools.chatbot.link" rel="noopener noreferrer">
            {{ campaign.tools.chatbot.link }}
          </a>
        </div>

        <div class="flex justify-center items-center w-full">
          <Button kind="primary" label="Close" @clicked="closeModal" />
        </div>
      </div>
    </Tile>
  </Modal>
</template>

<script>
import { Title, Button, Tile } from "cavea-design-system";

export default {
  name: "CampaignDetails",

  components: {
    Title,
    Tile,
    Button,
    Modal: () => import("@/components/Modal"),
  },

  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },

  computed: {
    campaignLanguages() {
      if (this.campaign?.info?.broadcastingLanguages?.length) {
        if (
          this.campaign?.info?.broadcastingLanguages?.filter((item) => item?.checked).length ===
          this.campaign?.info?.broadcastingLanguages?.length
        ) {
          return "All broadcasting languages allowed";
        }

        const checkedItems = this.campaign?.info?.broadcastingLanguages?.filter((item) => item?.checked);

        const shownItems = checkedItems?.splice(0, 3).map((elm) => elm?.value);

        return `${shownItems.join(", ")} ${checkedItems?.length > 0 ? ` + ${checkedItems?.length}` : ""}`;
      }

      return null;
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
